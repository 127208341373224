import ClevertapReact from "clevertap-react";

// old Id
// ClevertapReact.initialize("664-4ZR-7Z5Z");
// new id
const clevertapAcc = "8WW-Z5W-785Z";
const clevertapStaging = "8WW-Z5W-785Z";
// if (process.env.REACT_APP_BUILD_ENV == "staging")
//  ClevertapReact.initialize(clevertapStaging);
// else ClevertapReact.initialize(clevertapAcc);
// Registration of clevertap service:

function parseToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

function register() {
    try {
        let localToken = localStorage.getItem('Token');
        let signedUpUserDetails = localStorage.getItem('signedUpUserDetails')
        signedUpUserDetails = signedUpUserDetails ? JSON.parse(`${signedUpUserDetails}`) : null
        if (localToken) {
            let authTokenData = parseToken(localToken);
            if(authTokenData){
                // ClevertapReact.initialize(clevertapStaging);
                let details = {
                    userId: authTokenData.id,
                    orgId: authTokenData.orgId,
                    Identity: authTokenData.id,
                    Email: authTokenData.email,
                    Phone: '+' + authTokenData.mobile,
                    orgCode: authTokenData.orgCode,
                    name: authTokenData.name,
                    cp_sdk_source: "web",
                    is_user_id_even: getEvenUserId(authTokenData.id),
                }

                localStorage.setItem('partner-event', JSON.stringify(details))

                // window.clevertap.onUserLogin.push({
                //  Site: {...details}
                // });

                console.log('detailssss', details)
                // ClevertapReact.profile({ Site: { ...details } })
                 window.clevertap.profile.push({ Site: { ...details } });
            }
        }
        
        
    } catch (e) {
        console.error("error in clever tap track event", e);
        return;
    }
}

function Track(eventType) {
    // ClevertapReact.event("REFERRAL PROGRAM", {
            // ClevertapReact.event("REFERRAL_PROGRAM", 
            // { ACTION: eventType }
            //  );
            let  partnerData = {}
            if (localStorage.getItem('partner-event') != null){
                 partnerData =JSON.parse(localStorage.getItem('partner-event'));
            }
    // console.log(partnerData, 'partnerData')
    return  (
        window.clevertap.event.push("REFERRAL PROGRAM", 
        { 
            ACTION: eventType,
            ...partnerData
        }
        )
        // window.clevertap.event.push("REFERRAL_PROGRAM");
    );
        
        
}

export default {
    referNowClick() {
        Track("Refer now click");
    },
    rewardsClick() {
        Track("Rewards details click");
    },
    referFriendClick() {
        Track("Refer a friend click");
    },
    growth1Click() {
        Track("Growth story 1");
    },
    growth2Click() {
        Track("Growth story 2");
    },
    viewRewardFooter() {
        Track("View rewards click footer");
    },
    referFriendFooter() {
        Track("Refer a friend click footer");
    },
    termsClick() {
        Track("T&C click");
    },
    rewardStatusClick() {
        Track("Rewards status click");
    },
    rewardVerifiedClick() {
        Track("Rewards verified click");
    },
    register
};

export function getEvenUserId(id){
    return Number(id)%2==0 ? true :false
}
