function parseToken(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getUserCosmos = () => {
  let localToken = localStorage.getItem("Token");
  if (localToken) {
    let userData = parseToken(localToken);
    return {
      name: userData?.name,
      org_code: userData?.orgCode,
      org_id: userData?.orgId,
      phone: userData?.mobile,
      source_user_id: userData?.id ? userData.id + "" : undefined, // BE expects source_user_id as STRING, converting to string
      user_type: userData?.userType,
      email: userData?.email,
      is_userid_even: userData?.id ? userData.id % 2 === 0 : undefined,
      country_code: userData?.countryCode,
    };
  } else return null;
};
