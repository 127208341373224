import { user, initialize } from "cosmos";
import { getUserCosmos } from "./helper";

/**
 * initCosmos is a function used to call initialize method of SDK
 * which will initialize SDK. We can set configuration here.
 * Can also pass rest attributes.
 */
export const initCosmos = () => {
  let initialConfig = {
    eventLimit: 1,
    logging: true,
    env: process?.env?.REACT_APP_ENV,
    // uncomment debug to disable cosmos
    // debug: true,
  };
  /* Attributes you want to append in each user session call */
  let restAttributes = { source: 54, source_app: "classplus" };
  /*
   This function will call initialize fn of cosmos SDK
   initialize function expects optional config object which 
   is the configuration you want to set for SDK 
  */
  initialize(initialConfig, restAttributes);
};

/**
 * This function is used to call login method
 * of Cosmos SDK.
 * We can pass userAttributes as a param to SDK
 * login method.
 */
export const loginCosmos = () => {
  const userAttributes = getUserCosmos();
  if (userAttributes) {
    user.login(userAttributes);
  }
};

export const logoutCosmos = () => {
  /* Will clear token and userAttributes. */
  user.logout();
};

/**
 * This function is used to set user attributes
 * inside Cosmos SDK.
 */
export const updateUserCosmos = (userAttr = {}) => {
  const userAttributes = getUserCosmos();
  const updatedUserAttributes = { ...userAttributes, ...userAttr };
  user.updateAttributes(updatedUserAttributes);
};
