import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./App.scss";

// import Home from "./components/Home/Home";
// import MyRewards from "./components/MyRewards/MyRewards"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "semantic-ui-react";
import { baseUrl, mvBaseUrl } from "./config";
import CleverTap from "./CleverTap";
import { initCosmos, updateUserCosmos } from "./cosmosTracking";
import AccounDetailsModal from "./components/ModalComponents/AccountDetailsModal";
import KYCVerificationModal from "./components/ModalComponents/KYCVerificationModal";

// import TnC from "./components/TnC/TnC"

const HomeComponent = React.lazy(() => import("./components/Home/Home"));

const MyRewardComponent = React.lazy(() =>
  import("./components/MyRewards/MyRewards")
);

const TnComponent = React.lazy(() => import("./components/TnC/TnC"));

function FallBackLoader() {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Loader title="Loading" />
    </div>
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      infoApi: {},
    };
  }

  componentDidMount() {
    let paraToken = this.getQuery()["token"] || localStorage.getItem("Token");
    if (paraToken) {
      localStorage.setItem("Token", paraToken);
      CleverTap.register();
      updateUserCosmos();
    }
    this.getInfoApi();
    this.getAffiliateSales();
    initCosmos();
  }

  getQuery() {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    // console.log(b)
    return b;
  }

  getInfoApi = () => {
    fetch(`${baseUrl}/v2/partner/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.getQuery()["token"],
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ infoApi: res.data }, () =>
          console.log(this.state.apiData)
        );
        let paraToken =
          this.getQuery()["token"] || localStorage.getItem("Token");
        localStorage.setItem("Token", paraToken);
      })
      .catch((err) => console.log(err.message));
  };

  getAffiliateSales = () => {
    fetch(`${mvBaseUrl}/v2/affiliateStats`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("Token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem("affiliateTotalSales", res.data.totalAmountEarned);
      })
      .catch((err) => console.log(err.message));
  };

  render() {
    let { infoApi } = this.state;
    return (
      <div className="App">
        <ToastContainer autoClose={3000} />
        <BrowserRouter>
          <Suspense fallback={<FallBackLoader />}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <HomeComponent infoApiResponse={infoApi} />}
              />
              <Route exact path="/MyRewards" component={MyRewardComponent} />
              <Route exact path="/TnC" component={TnComponent} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Suspense>
        </BrowserRouter>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
