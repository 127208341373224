const URL_OBJECT = () => {
  switch (process?.env?.REACT_APP_ENV) {
    case "development":
      return "https://api.classplusapp.com";
    case "staging":
      return "https://api.classplusapp.com";
    case "preprod":
      return "https://apip-gcp.classplusapp.com";
    case "production":
      return "https://api.classplusapp.com";
    default:
      return "https://api.classplusapp.com";
  }
};

let BASE_URL = URL_OBJECT();

export const baseUrl = BASE_URL;
export const mvBaseUrl = "https://crm.classplus.co";

// eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE5NzExMTUzMDc5IiwidHlwZSI6MywiaWF0IjoxNjEwNTE3NjQ1LCJleHAiOjE2MTExMjI0NDV9.HHMtiQdK0hB75cmO979pyl-BVo4LgVX67Zg82qUrlB0ooz9QDKgyLw1MkBi-GLFW
