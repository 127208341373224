import React, { Component } from "react";
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import Axios from "axios";
import { baseUrl, mvBaseUrl } from "../../config";
import { toast } from "react-toastify";
import AccounDetailsModal from "./AccountDetailsModal";
import TickDot from "../../images/kyc_tick_dot.png";
import { useState } from "react";

const KYCVerificationModal = (props) => {
  const [isAccountDetailsModaOpen, setIsAccountDetailModalOpen] =
    useState(false);

  return (
    <>
      <Modal
        toggle={props.toggleVerifyModal}
        hidden={!props.isVerifyKYCModalOpen}
      >
        <FormatModal>
          {props.isVerifyKYCModalOpen && (
            <KYCVerificationDetails
              toggleVerifyModal={props.toggleVerifyModal}
              setIsAccountDetailModalOpen={setIsAccountDetailModalOpen}
            />
          )}
        </FormatModal>
      </Modal>
      <AccounDetailsModal
        isAccountDetailsModalOpen={isAccountDetailsModaOpen}
        toggleAccountDetailsModal={() => {
          setIsAccountDetailModalOpen(!isAccountDetailsModaOpen);
        }}
      />
    </>
  );
};

class KYCVerificationDetails extends Component {
  // TO BE STARTED --> 0
  // IN PROGRESS --> 1
  // COMPLETED --> 2
  state = {
    formSubmissionCompleted: false,
    formDetailsSaved: false,
    isPanUploaded: false,
    isBankAcUploaded: false,
    isSelfieUploaded: false,
    panCardFile: null,
    bankAcFile: null,
    selfieFile: null,
    bankAcFileName: "",
    selfieFileName: "",
    panCardFileName: "",
    selfieFileURL: "",
    bankAcFileURL: "",
    panCardFileURL: "",
    isVerified: false,
    isUploaded: false,
    isAccountDetailsModalOpen: false,
  };

  async getAccountDetails() {
    try {
      const { data } = await Axios.get(`${baseUrl}/v2/partner/accountDetails`, {
        headers: {
          "x-access-token": localStorage.getItem("Token"),
        },
      });

      if (data.data.accountDetails[0].panImage ) {
        this.setState({
          isPanUploaded: true,
          panCardFileName: data.data.accountDetails[0].panImage,
          panCardFileURL: data.data.accountDetails[0].panImage,
        });
      }

      if (data.data.accountDetails[0].Passbook ) {
        this.setState({
          isBankAcUploaded: true,
          bankAcFileName: data.data.accountDetails[0].Passbook,
          bankAcFileURL: data.data.accountDetails[0].Passbook,
        });
      }

      if (data.data.accountDetails[0].Self_Image ) {
        this.setState({
          isSelfieUploaded: true,
          selfieFileName: data.data.accountDetails[0].Self_Image,
          selfieFileURL: data.data.accountDetails[0].Self_Image,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.getAccountDetails();
  }

  handleFormSubmission = async () => {
    const { selfieFileURL, bankAcFileURL, panCardFileURL } = this.state;

    try {
      await Axios.patch(
        `${baseUrl}/v2/partner/kycDetails`,
        {
          passbook: bankAcFileURL,
          selfie: selfieFileURL,
          panImage: panCardFileURL,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("Token"),
          },
        }
      );
      toast.success("All images saved successfully!");
      this.props.toggleVerifyModal();
      this.props.setIsAccountDetailModalOpen(true);
    } catch (err) {
      toast.error("API error!");
    }
  };

  handleFileUpload = async (e, uploadType) => {
    if (e.target.files[0].type.split("/")[0] !== "image") {
      toast.error("Please upload an image file!");
      return;
    }

    let formdata = new FormData();
    let file = e.target.files[0];
    let fileURL = null;
    formdata.append("files", file);
    formdata.append("type", file.type.split("/")[1]);

    try {
      const { data } = await Axios.post(
        `${mvBaseUrl}/su/uploader/open`,
        formdata,
        {
          headers: {
            "x-access-token": localStorage.getItem("Token"),
          },
        }
      );
      fileURL = data.data.key;
    } catch (err) {
      console.log({ err });
      toast.error("API error");
    }

    if (file) {
      switch (uploadType) {
        case "bankAc":
          this.setState(
            {
              isBankAcUploaded: true,
              bankAcFile: file,
              bankAcFileName: file.name,
              bankAcFileURL: fileURL,
            },
            () => toast.success("Bank account image uploaded!")
          );
          break;

        case "selfie":
          this.setState(
            {
              isSelfieUploaded: true,
              selfieFile: file,
              selfieFileName: file.name,
              kycSelfieStatus: 2,
              selfieFileURL: fileURL,
            },
            () => toast.success("Selfie uploaded!")
          );
          break;

        case "panCard":
          this.setState(
            {
              isPanUploaded: true,
              panCardFile: file,
              panCardFileName: file.name,
              panCardFileURL: fileURL,
            },
            () => toast.success("Pan Card Image Uplaoded")
          );

        default:
          return;
      }
    }
  };

  handleBankAcUpload = (e) => {
    this.handleFileUpload(e, "bankAc");
  };

  handlePanUpload = (e) => {
    this.handleFileUpload(e, "panCard");
  };

  handleSelfieUpload = (e) => {
    this.handleFileUpload(e, "selfie");
  };

  render() {
    const {
      formSubmissionCompleted,
      formDetailsSaved,
      kycBankAcStatus,
      kycSelfieStatus,
      isSelfieUploaded,
      isBankAcUploaded,
      bankAcFileName,
      selfieFileName,
      isVerified,
      isPanUploaded,
      panCardFileName,
    } = this.state;

    console.log(this.state);

    return (
      <div className="kyc__verification__details__container">
        <div className="kyc__verification__details__container__header">
          <p className="kyc__verification__details__container__header__title">
            Complete your KYC
          </p>
        </div>
        <div className="kyc__verification__details__container__body kyc__responsive">
          {isVerified ? (
            <div className="kyc__verification__details__container__body__submitted__box">
              Your KYC details have been verified
            </div>
          ) : formDetailsSaved || formSubmissionCompleted ? (
            <div className="kyc__verification__details__container__body__submitted__box">
              Thanks for adding your KYC details! We will verify them soon!
            </div>
          ) : (
            <div className="kyc__verification__details__container__body__disclaimer__box">
              KYC details should match with your bank account details, else
              payment will not be processed
            </div>
          )}

          {/* PAN CARD STARTS HERE */}
          <div
            style={{
              height: "150px",
            }}
            className="kyc__verification__details__container__body__section2"
          >
            <div className="kyc__verification__details__container__body__progress__bar">
              <div
                className="kyc__verification__details__container__body__progress__dot"
                style={{
                  border: isPanUploaded ? "none" : "1px solid #009AE0",
                }}
              >
                {isPanUploaded ? (
                  <div>
                    <img src={TickDot} alt="" />{" "}
                  </div>
                ) : (
                  <p>1</p>
                )}
              </div>
              <div
                className="kyc__verification__details__container__body__progress__line"
                style={kycBankAcStatus === 2 ? { borderColor: "#009AE0" } : {}}
              ></div>
            </div>
            <div className="kyc__verification__section1__main__box">
              <div
                style={{
                  fontWeight: "700",
                }}
                className="kyc__verification__details__container__body__section1__heading"
              >
                Add Pan Card
              </div>
              <div className="kyc__verification__details__container__body__section1__subheading">
                Please upload image of your PAN Card
              </div>
              {isPanUploaded ? (
                <div className="kyc__file__name__holder">
                  <p>PanImage.jpg</p>
                </div>
              ) : (
                <label className="kyc__upload__button">
                  <input
                    type="file"
                    onChange={(e) => this.handlePanUpload(e)}
                  />
                  Upload PAN Card
                </label>
              )}
            </div>
          </div>
          {/* PAN CARD ENDS HERE */}

          {/* BANK ACCOUNT STARTS HERE */}
          <div
            style={{
              height: "160px",
            }}
            className="kyc__verification__details__container__body__section2"
          >
            <div className="kyc__verification__details__container__body__progress__bar">
              <div
                className="kyc__verification__details__container__body__progress__dot"
                style={{
                  border: isBankAcUploaded ? "none" : "1px solid #009AE0",
                }}
              >
                {isBankAcUploaded ? (
                  <div>
                    <img src={TickDot} alt="" />
                  </div>
                ) : (
                  <p>2</p>
                )}
              </div>
              <div
                className="kyc__verification__details__container__body__progress__line"
                style={kycBankAcStatus === 2 ? { borderColor: "#009AE0" } : {}}
              ></div>
            </div>
            <div className="kyc__verification__section1__main__box">
              <div
                style={{
                  fontWeight: "700",
                }}
                className="kyc__verification__details__container__body__section1__heading"
              >
                Add Bank Acccount Proof
              </div>
              <div className="kyc__verification__details__container__body__section1__subheading">
                Please upload an image of a cancelled cheque OR bank passbook
              </div>
              {isBankAcUploaded ? (
                <div className="kyc__file__name__holder">
                  <p>PassbookImage.jpg</p>
                </div>
              ) : (
                <label className="kyc__upload__button">
                  <input
                    type="file"
                    onChange={(e) => this.handleBankAcUpload(e)}
                  />
                  Upload Proof
                </label>
              )}
            </div>
          </div>
          {/* BANK ACCOUNT ENDS HERE */}

          {/* SELFIE STARTS HERE */}
          <div
            className="kyc__verification__details__container__body__section3"
            style={{ height: "138px" }}
          >
            <div className="kyc__verification__details__container__body__progress__bar">
              <div
                className="kyc__verification__details__container__body__progress__dot"
                style={{
                  border: isSelfieUploaded ? "none" : "1px solid #009AE0",
                }}
              >
                {isSelfieUploaded ? (
                  <div>
                    <img src={TickDot} alt="" />
                  </div>
                ) : (
                  <p>3</p>
                )}
              </div>
            </div>
            <div className="kyc__verification__section1__main__box">
              <div
                style={{
                  fontWeight: "700",
                }}
                className="kyc__verification__details__container__body__section1__heading"
              >
                Add a selfie!
              </div>
              <div className="kyc__verification__details__container__body__section1__subheading">
                {"Click & add your clear image in a well lit environment"}
              </div>
              {isSelfieUploaded ? (
                <div className="kyc__file__name__holder">
                  <p>Selfie.jpg</p>
                </div>
              ) : (
                <label className="kyc__upload__button">
                  <input
                    type="file"
                    onChange={(e) => this.handleSelfieUpload(e)}
                  />
                  Upload Selfie
                </label>
              )}
            </div>
          </div>
          {/* SELFIE ENDS HERE */}

          {/* confirmation button */}

          <div
            style={{
              margin: "16px 0",
            }}
          >
            {isVerified ? (
              <button
                className="go__to__ac__details__button"
                onClick={() => {
                  this.props.openAcDetailsModal(true);
                }}
              >
                <p>Go to account details</p>
              </button>
            ) : (
              <div
                style={{
                  margin: "2.4rem 2.4rem 0rem",
                }}
              >
                <button
                  style={{
                    marginTop: "16px",
                    background: "#009AE0",
                    borderRadius: "4px",
                    padding: "8px 0",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    width: "100%",
                    outline: "none",
                    alignItems: "center",
                    textAlign: "right",
                    border: "none",
                    color: "#ffffff",
                  }}
                  className="save__continue__button"
                  onClick={this.handleFormSubmission}
                >
                  <p>{`Save & Continue`}</p>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default KYCVerificationModal;
