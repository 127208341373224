import React, { useEffect } from "react";
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import { useState } from "react";
import Axios from "axios";
import { baseUrl } from "../../config";
import { toast } from "react-toastify";

// const INITIAL_STATE = {
//   accountNumber: "",
//   bankName: "",
//   ifscCode: "",
//   accountHolderName: "",
//   panCardNumber: "",
// };

const AccounDetailsModal = (props) => {
  console.log(props);
  // const [accountDetailUpdate,setAccountDetailUpdate] = useState(INITIAL_STATE)
  const [accountDetails, setAccountDetails] = useState("");

  // /v2/partner/accountDetails -> POST

  const sumitAccountDetails = async () => {
    try {
      await Axios.post(
        `${baseUrl}/v2/partner/accountDetails`,
        {
          ...accountDetails,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("Token"),
          },
        }
      );
      toast.success("Account Details Submitted Successfully");
      props.toggleAccountDetailsModal();
    } catch (err) {
      toast.error("Something Went Wrong");
    }
  };

  const getAccountDetails = async () => {
    try {
      const { data } = await Axios.get(`${baseUrl}/v2/partner/accountDetails`, {
        headers: {
          "x-access-token": localStorage.getItem("Token"),
        },
      });
      console.log(data.data.accountDetails[0], "pompom");
      setAccountDetails(data.data.accountDetails[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAccountDetails();
   
  }, []);
  return (
    <Modal
      hidden={!props.isAccountDetailsModalOpen}
      toggle={props.toggleAccountDetailsModal}
    >
      <FormatModal>
        <div className="accountDetailsModal">
          <div
            style={{
              padding: "1.2rem 2.4rem 2.4rem",
              borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            }}
            className="accountDetailModalHeader"
          >
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
              }}
              className="accountDetaulsModalTitle"
            >
              Add Account Details
            </p>
          </div>
          <div
            style={{
              margin: "2.4rem 2.4rem 0rem",
            }}
            className="accountDetailsForm"
          >
            <form>
              <div className="ReferModal-Content-InputFileld">
                <input
                  type="text"
                  className="ReferModal-Content-InputFileld-Input"
                  placeholder="Account Number"
                  value={accountDetails?.accountNumber}
                  onChange={(e) => {
                    setAccountDetails({
                      ...accountDetails,
                      accountNumber: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReferModal-Content-InputFileld">
                <input
                  type="text"
                  className="ReferModal-Content-InputFileld-Input"
                  placeholder="Bank Name"
                  value={accountDetails?.bankName}
                  onChange={(e) => {
                    setAccountDetails({
                      ...accountDetails,
                      bankName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReferModal-Content-InputFileld">
                <input
                  type="text"
                  className="ReferModal-Content-InputFileld-Input"
                  placeholder="IFSC Code"
                  value={accountDetails?.ifscCode}
                  onChange={(e) => {
                    setAccountDetails({
                      ...accountDetails,
                      ifscCode: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReferModal-Content-InputFileld">
                <input
                  type="text"
                  className="ReferModal-Content-InputFileld-Input"
                  placeholder="Account Holder Name"
                  value={accountDetails?.accountHolderName}
                  onChange={(e) => {
                    setAccountDetails({
                      ...accountDetails,
                      accountHolderName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReferModal-Content-InputFileld">
                <input
                  type="text"
                  className="ReferModal-Content-InputFileld-Input"
                  placeholder="Pan Card Number"
                  value={accountDetails?.panCardNumber}
                  onChange={(e) => {
                    setAccountDetails({
                      ...accountDetails,
                      panCardNumber: e.target.value,
                    });
                  }}
                />
              </div>

              <button
                style={{
                  marginTop: "32px",
                  background: "#009AE0",
                  borderRadius: "4px",
                  padding: "8px 0",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  width: "100%",
                  outline: "none",
                  alignItems: "center",
                  textAlign: "right",
                  border: "none",
                  color: "#ffffff",
                }}
                type="button"
                onClick={sumitAccountDetails}
              >
                Save & Continue
              </button>
            </form>
          </div>
        </div>
      </FormatModal>
    </Modal>
  );
};

export default AccounDetailsModal;
